import React from "react";
import { baseUrl } from "../baseUrl";
import { useNavigate } from "react-router-dom";
import { fetchInfo } from "../ApiServices";
import ChartCrypto from "../components/Chart";

function Info({ rvn }) {
    const navigate = useNavigate();
    let newBaseUrl = rvn ? baseUrl + "/ravencoin" : baseUrl;
    let cripto = rvn ? "RVN" : "BTC";
    const [data, setData] = React.useState([]);
    const [labels, setLabels] = React.useState([]);
    const [btcValues, setBtcValues] = React.useState([]);
    const [usdtValues, setUsdtValues] = React.useState([]);

    const goBack = () => {
        let back = rvn ? "/ravencoin" : "/";
        navigate(back);
    };

    React.useEffect(() => {
        let token = localStorage.getItem("token");
        fetchInfo(newBaseUrl, token).then((res) => {
            setData(res);
        });
    }, []);

    React.useEffect(() => {
        // order data by timestamp
        data.sort((a, b) => a.timestamp - b.timestamp);
        const labels = data.map((item) =>
            new Date(item.timestamp * 1000).toLocaleDateString()
        );
        const btcValues = data.map((item) => parseFloat(item.balanceBTC)); // Use 'balanceBTC'
        const usdtValues = data.map((item) => parseFloat(item.balanceUSDT)); // Use 'balanceUSDT'
        setLabels(labels);
        setBtcValues(btcValues);
        setUsdtValues(usdtValues);
        console.log(data);
    }, [data]);

    if (rvn) {
        return <h1>Sorry, no chart for RVN yet</h1>;
    }
    return (
        <div className="wrapper">
            <div className="infoTitle">
                Insights for {cripto}
                <button className="logsFetch" onClick={goBack}>
                    Go back
                </button>
            </div>
            {usdtValues && (
                <div className="chartWrapper">
                    <ChartCrypto
                        labels={labels}
                        btcValues={btcValues}
                        usdtValues={usdtValues}
                    />
                </div>
            )}
            <div className="">
                <h1>Balance history</h1>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th></th>
                            <th>{cripto}</th>
                            <th>USDT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                            <tr key={item.timestamp}>
                                <td className={item.action === "BUY" ? "green" : "red"}>
                                    {new Date(
                                        item.timestamp * 1000
                                    ).toLocaleDateString()}
                                </td>
                                <td>{item.balance}</td>
                                <td>₿ {item.balanceBTC.slice(0, item.balanceBTC.indexOf('.') + 6)}</td>
                                <td>$ {item.balanceUSDT}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="legenda">
                    <div className="legendaGreen"></div> <span>=</span> <span>Buy</span>
                    <div className="legendaRed"></div> <span>=</span> <span>Sell</span>
                </div>
            </div>
        </div>
    );
}

export default Info;
