import React from "react";
import { getBalance, startBot, stopBot, checkIsValid } from "../ApiServices";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { useEffect } from "react";
import { baseUrl } from "../baseUrl";

function Home({rvn}) {

    let newBaseUrl = !!rvn ? baseUrl+'/ravencoin' : baseUrl;

    const [balance, setBalance] = React.useState([]);
    const [botStatus, setBotStatus] = React.useState(0);
    const [btcPrice, setBtcPrice] = React.useState(0);
    const [avgBuyPrice, setavgBuyPrice] = React.useState(0);
    const [averageSellPrice, setAverageSellPrice] = React.useState(0);
    const [nextAction, setNextAction] = React.useState("");
    const [isAuth, setIsAuth] = React.useState(false);
    const [token, setToken] = React.useState(
        localStorage.getItem("token") || ""
    );
    const [priceAtWillBuy, setPriceAtWillBuy] = React.useState(0);
    const [priceAtWillSell, setPriceAtWillSell] = React.useState(0);
    const [serverConnected, setServerConnected] = React.useState(false);
    const [remaining, setRemaining] = React.useState(0);
    const [logs, setLogs] = React.useState("");
    const [version, setVersion] = React.useState("");

    const navigate = useNavigate();

    const goSeeLogs = () => {
        let logsUrl = rvn ? '/ravencoin/logs' : '/logs'
        navigate(logsUrl)
    }

    const goSeeInfo = () => {
        let infoUrl = rvn ? '/ravencoin/info' : '/info'
        navigate(infoUrl)
    }

    const check = async () => {
        let isValid = await checkIsValid(token, newBaseUrl);
        let a = isValid === "true" ? 1 : 0;

        setIsAuth(a);

        if (!a) {
            // delete token from local storage
            localStorage.removeItem("token");
            navigate(`/login`);
        }
    };

    useEffect(() => {
        document.body.style.zoom = "100%";
        let token = localStorage.getItem("token");
        setToken(token);

        if (!token) {
            rvn ? navigate(`/ravencoin/login`) : navigate('/login');
        } else check();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const socket = rvn ? io(baseUrl, { path: '/ravencoin/socket.io' }) : io(baseUrl);
        socket.on("connect", () => console.log(socket.id));

        socket.on("status", (data) => {
            setBotStatus(data.botStatus);
            setRemaining(parseInt(data.remaining));
            setVersion(data.version);
            setServerConnected(true);
        });

        socket.on("data", (data) => {
            setBtcPrice(data.openPrice);
            setavgBuyPrice(data.avgBuyPrice);

            setAverageSellPrice(data.averageSellPrice);
            setPriceAtWillBuy(data.priceAtWillBuy);
            setPriceAtWillSell(data.priceAtWillSell);
            setServerConnected(true);

            // let newAct = data.buy ? "BUY" : "SELL";
            // setNextAction(newAct);
        });

        socket.on("connect_error", () => {
            setServerConnected(false);
            setTimeout(() => socket.connect(), 5000);
        });
        socket.on("disconnect", () => console.log("server disconnected"));

        // Clean up the socket connection on unmount
        return () => {
            socket.disconnect();
        };
    }, []);

    let i = 0;
    function handleStart() {
        let newBaseUrl = rvn ? baseUrl+'/ravencoin' : baseUrl;
        startBot(newBaseUrl)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleStop() {
        let newBaseUrl = rvn ? baseUrl+'/ravencoin' : baseUrl;
        stopBot(newBaseUrl)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchBalance() {
        let newBaseUrl = rvn ? baseUrl+'/ravencoin' : baseUrl;
        getBalance(newBaseUrl)
            .then((res) => {
                // make array with key value of res
                const b = Object.keys(res).map((key) => {
                    return `${key}: ${res[key]}`;
                });

                setBalance(b);
            })
            .catch((err) => {
                setBalance([]);
            });
    }

    return !isAuth || !serverConnected ? (
        <div className="loadingWrapper">
            <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
            />
        </div>
    ) : (
        <div className="wrapper">
            <div className="d-flex justify-content-center align-center dnh">
                { rvn ? <img className="logo" src={`${process.env.PUBLIC_URL}/rvn.png`} alt="RVN" /> : <img className="logo" src={`${process.env.PUBLIC_URL}/btc.png`} alt="BTC" />}
                { rvn ? 'RAVENCOIN TRADER' : 'BITCOIN TRADER'} 
                { !rvn ? <p className="link"><a href="/ravencoin">Go to Ravencoin Trader</a></p> :  <p><a href="/">Go to Bitcoin Trader</a></p>}
                <hr></hr>
                <div className="d-flex justify-content-center align-center dnh">
                    {+botStatus === 1 ? (
                        <label className="running">RUNNING</label>
                    ) : (
                        <label className="stopped">STOPPED</label>
                    )}
                </div>
                <label className="remaining">
                    Next action in: {new Date(remaining).getUTCHours()} Hours{" "}
                    {new Date(remaining).getUTCMinutes()} minutes{" "}
                </label>
            </div>
            <div className="nextAction">
                { rvn ? <p> Would BUY 100% at: $ {+priceAtWillBuy.toFixed(5)} or below </p> : <p> Would BUY 100% at: $ {+priceAtWillBuy.toFixed(2)} or below </p>}
                <p> ----- </p>
                { rvn ? <p> Would SELL 2.5% at: $ {+priceAtWillSell.toFixed(5)} or above </p> : <p> Would SELL 12.5% at: $ {+priceAtWillSell.toFixed(2)} or above </p>}
            </div>
            <div className="trade">
                <button className="bottone" onClick={handleStart}>
                    START
                </button>
                <button className="bottone" onClick={handleStop}>
                    STOP
                </button>
            </div>
            <div className="prezzi">
                <div className="d-flex price dnh">
                    { !rvn ? "BTC/USDT:" : "RVN/USDT:"}
                    <span>{+btcPrice}</span>
                </div>
                <div className="divider"></div>
                <div className="d-flex price dnh">
                    AVG BUY PRICE:
                    <span>{+avgBuyPrice.toFixed(5)}</span>
                </div>
                <div className="divider"></div>
                <div className="d-flex price dnh">
                    AVG SELL PRICE:
                    <span>{+averageSellPrice.toFixed(5)}</span>
                </div>
            </div>
            <button className="bottone" onClick={fetchBalance}>
                See Balance 💰
            </button>
            <div className="d-flex justify-content-center align-center balance">
                {balance.length &&
                    balance.map((item) => {
                        return (
                            <div className="prezzo" key={i++}>
                                {item}
                            </div>
                        );
                    })}
            </div>
            <div className="bottoniDHN">
                <button className="bottone" onClick={goSeeLogs}>
                    Go to Logs
                </button>
                <button className="bottone" onClick={goSeeInfo}>
                    Info Trading
                </button>
            </div>
        </div>
    );
}

export default Home;
