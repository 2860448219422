import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import Logs from "./pages/Logs";
import Info from "./pages/Info";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/ravencoin" element={<HomeWrapper rvn={true} />} />
                <Route path="/ravencoin/settings" element={<SettingsWrapper rvn={true} />} />
                <Route path="/ravencoin/login" element={<LoginWrapper rvn={true} />} />
                <Route path="/ravencoin/logs" element={<LogsWrapper rvn={true} />} />
                <Route path="/ravencoin/info" element={<InfoWrapper rvn={true} />} />
                <Route path="/" element={<HomeWrapper rvn={false} />} />
                <Route path="/settings" element={<SettingsWrapper rvn={false} />} />
                <Route path="/login" element={<LoginWrapper rvn={false} />} />
                <Route path="/logs" element={<LogsWrapper rvn={false} />} />
                <Route path="/info" element={<InfoWrapper rvn={false} />} />
                <Route path="*" element={<NoPage />} />
            </Routes>
        </BrowserRouter>
    );
}

function HomeWrapper(props) {
    return <Home {...props}/>;
}

function LoginWrapper(props) {
    return <Login {...props}/>;
}

function SettingsWrapper(props) {
    return <Settings {...props}/>;
}

function LogsWrapper(props) {
    return <Logs {...props} />;
}

function InfoWrapper(props) {
    return <Info {...props} />;
}

export default App;
