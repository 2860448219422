import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ChartCrypto = ({ labels, btcValues, usdtValues }) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            // title: {
            //     display: true,
            //     text: "Chart.js Line Chart",
            // },
        },
        scales: {
            y: {
                type: "linear",
                display: true,
                position: "left",
                ticks: {
                    precision: 5,
                }
            },
            y1: {
                type: "linear",
                display: true,
                position: "right",

                // grid line settings
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: "BTC",
                data: btcValues,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                yAxisID: "y",
                tension: 0.2,
            },
            {
                label: "USDT",
                data: usdtValues,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
                yAxisID: "y1",
                tension: 0.2,
            },
        ],
    };

    return <Line options={options} data={data} />;
};

export default ChartCrypto;
