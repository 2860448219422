import React from "react";
import { baseUrl } from "../baseUrl";

function Settings({rvn}) {

    let newBaseUrl = !!rvn ? baseUrl+'/ravencoin' : baseUrl;

    const [secretFlag, setSecretFlag] = React.useState(false);
    const handleSubmitSecret = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        if (data.get("secret") === "superMegaSecretThingy") {
            setSecretFlag(true);
        }
    };

    const handleSubmitSeed = (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token");
        const data = new FormData(e.target);
        const buyFlag = data.get("buyFlag");
        const sellFlag = data.get("sellFlag");
        const btcBalance = data.get("btcBalance");
        const usdtBalance = data.get("usdtBalance");
        const lastBuyPrice = data.get("lastBuyPrice");
        const lastSellPrice = data.get("lastSellPrice");
        const lastBuyBtcTot = data.get("lastBuyBtcTot");
        const seed = {
            buyFlag,
            sellFlag,
            btcBalance,
            usdtBalance,
            lastBuyPrice,
            lastSellPrice,
            lastBuyBtcTot
        };
        console.log(seed);
        fetch(`${newBaseUrl}/api/seed`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(seed),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
            })
            .catch((err) => console.log(err));
    };

    const handleWipeData = (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token");
        fetch(`${newBaseUrl}/api/wipe`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
            })
            .catch((err) => console.log(err));
    };

    const handleSubmitAuth = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const username = data.get("username");
        const password = data.get("password");
        const auth = {
            username,
            password,
        };
        console.log(auth);
        fetch(`${newBaseUrl}/api/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(auth),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
            })
            .catch((err) => console.log(err));
    };

    return !secretFlag ? (
        <div className="wrapperTwo">
            <form className="m-login-form" onSubmit={handleSubmitSecret}>
                <input
                    type="text"
                    name="secret"
                    placeholder="secret"
                    required
                />
                <input type="submit" value="SHOW" />
            </form>
        </div>
    ) : (
        <div className="wrapperTwo">
            <div className="dnh">
                <h1> TRADER DHN </h1>
                <h2>Settings</h2>
                <form className="m-login-form" onSubmit={handleSubmitSeed}>
                    <div className="srl">
                        <h3>IMPOSTAZIONI SEED DB</h3>
                        <input
                            type="text"
                            name="buyFlag"
                            placeholder="buyFlag"
                            list="buyFlag"
                            required
                        />
                        <datalist id="buyFlag">
                            <option>true</option>
                            <option>false</option>
                        </datalist>

                        <input
                            type="text"
                            name="sellFlag"
                            placeholder="sellFlag"
                            required
                        />
                        <input
                            type="text"
                            name="btcBalance"
                            placeholder="btcBalance"
                            required
                        />
                        <input
                            type="text"
                            name="usdtBalance"
                            placeholder="usdtBalance"
                            required
                        />
                        <input
                            type="text"
                            name="lastBuyPrice"
                            placeholder="lastBuyPrice"
                            required
                        />
                        <input
                            type="text"
                            name="lastSellPrice"
                            placeholder="lastSellPrice"
                            required
                        />
                        <input
                            type="text"
                            name="lastBuyBtcTot"
                            placeholder="lastBuyBtcTot"
                            required
                        />
                        <input type="submit" value="SEED DB" />
                    </div>
                </form>
            </div>
            <div className="dnh">
                <h2>Settings</h2>
                <form className="m-login-form" onSubmit={handleSubmitAuth}>
                    <div className="srl">
                        <h3>IMPOSTAZIONI REGISTER</h3>
                        <input
                            type="text"
                            name="username"
                            placeholder="username"
                            required
                        />
                        <input
                            type="text"
                            name="password"
                            placeholder="password"
                            required
                        />
                        <input type="submit" value="REGISTER" />
                    </div>
                </form>
            </div>
            <div className="dnh">
                <h2>Settings</h2>
                <form className="m-login-form" onSubmit={handleWipeData}>
                    <div className="srl">
                        <h3>IMPOSTAZIONI WIPE</h3>
                        <input type="submit" value="WIPE" />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Settings;
