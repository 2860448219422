// import { baseUrl } from "./baseUrl";

export const getBalance = async (baseUrl) => {
    const response = await fetch(`${baseUrl}/api/balance`, {
        method: "GET",
        headers: {
            "Content-type": "Application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    });
    const data = await response.json();
    return data;
};

export const startBot = async (baseUrl) => {
    const response = await fetch(`${baseUrl}/api/start`, {
        method: "POST",
        headers: {
            "Content-type": "Application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    });
    const data = await response.text();
    return data;
};

export const stopBot = async (baseUrl) => {
    const response = await fetch(`${baseUrl}/api/stop`, {
        method: "POST",
        headers: {
            "Content-type": "Application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    });
    const data = await response.text();
    return data;
};

export const checkIsValid = async (token, baseUrl) => {
    const response = await fetch(`${baseUrl}/api/validateToken`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ token }),
    });
    const data = await response.text();
    return data;
};

export const loginUser = async (username, password, baseUrl) => {
    const user = { username, password };
    const response = await fetch(`${baseUrl}/api/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(user),
    });
    const data = await response.json();
    return data;
};

export const fetchInfo = async (baseUrl, token) => {
    const response = await fetch(`${baseUrl}/api/info`, {
        method: "GET",
        headers: {
            "Content-type": "Application/json",
            Authorization: "Bearer " + token,
        },
    });
    const data = await response.json();
    return data;
}
