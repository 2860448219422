import React, { useEffect, useState } from "react";
import { baseUrl } from "../baseUrl";
import { useNavigate } from "react-router-dom";

function Logs({rvn}) {
    let newBaseUrl = !!rvn ? baseUrl+'/ravencoin' : baseUrl;
    const navigate = useNavigate();
    const [logs, setLogs] = useState("");
    const fetchLogs = async () => {
        // get token from local storage
        const token = localStorage.getItem("token");
        try {
            const response = await fetch(`${newBaseUrl}/api/logs`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            let data = await response.text();
            data = data.replace(/was set to/g, "->");
            data = data.replace(/itsTimeToBuyOrSell/g, "itsTimeToSell");
            setLogs(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchLogs();
    }, []);

    const goBack = () => {
        let back = rvn ? '/ravencoin' : '/'
        navigate(back)
    }

    return (
        <div className="logs">
            <button className="logsFetch" onClick={goBack}>Go back</button>
            <pre className="pre">{logs}</pre>
        </div>
    );
}

export default Logs;
