import React, { useEffect } from "react";
import { loginUser } from "../ApiServices";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../baseUrl";

function Login({rvn}) {
    const navigate = useNavigate();

    let newBaseUrl = !!rvn ? baseUrl+'/ravencoin' : baseUrl;

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token) {
            rvn ? navigate(`/ravencoin`) : navigate('/');
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let { uname, pass } = document.forms[0];
        loginUser(uname.value, pass.value, newBaseUrl).then((res) => {
            if (res) {
                if (res.error) {
                    alert(res.error);
                    return;
                } else {
                    localStorage.setItem("token", res.token);
                    return rvn ? navigate(`/ravencoin`) : navigate('/');
                }
            }
        });
    };

    return (
        <div className="wrapper">
            <form className="m-login-form" onSubmit={handleSubmit}>
                <div className="srl">
                    <h3>TRADER DHN SRL</h3>
                    {
                        rvn ? <p className="colorBlack"> RVN version </p> : <p className="colorBlack"> BTC version </p>
                    }
                    <input
                        type="text"
                        name="uname"
                        placeholder="Username"
                        required
                    />
                    <input
                        type="password"
                        name="pass"
                        placeholder="Password"
                        required
                    />
                    <input type="submit" value="Login" />
                </div>
            </form>
            {/* <button onClick={() => navigate(`/settings`)}>Settings</button> */}
        </div>
    );
}

export default Login;
