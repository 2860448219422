import React from "react";

function NoPage() {
    return (
        <div className="dnh">
            <h1> TRADER DHN </h1>
            <h2>404</h2>
            <h2>Page not found</h2>
            <a href="/">
                <h1>Go to home</h1>
            </a>
        </div>
    );
}

export default NoPage;
